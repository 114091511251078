import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BlobServiceClient } from '@azure/storage-blob';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Button } from 'react-bootstrap';
import { CircularProgress } from '@material-ui/core';
const connectionString = "BlobEndpoint=https://xinvestment.blob.core.windows.net/;QueueEndpoint=https://xinvestment.queue.core.windows.net/;FileEndpoint=https://xinvestment.file.core.windows.net/;TableEndpoint=https://xinvestment.table.core.windows.net/;SharedAccessSignature=sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-02-18T08:40:12Z&st=2024-02-18T00:40:12Z&spr=https&sig=ARIuwMks5sf3UXSsyKOepn2%2F0QCTQXQv%2FIP44GVYJBQ%3D";
const containerName = "fft-image";

function ImgRender() {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState('Focus');
  // const focusGroup = ['TU.png', 'BAM.png', 'GFPT.png'];
  const focusGroup = ['ADVANC.png', 'AOT.png', 'AP.png', 'AWC.png', 'BA.png', 'BAM.png', 'BANPU.png', 'BBL.png', 'BCH.png', 'BCP.png', 'BDMS.png', 'BEM.png', 'BGRIM.png', 'BJC.png', 'BLA.png', 'BTS.png', 'CENTEL.png', 'CK.png', 'COM7.png', 'CPALL.png', 'CPF.png', 'CPN.png', 'CRC.png', 'EGCO.png', 'GLOBAL.png', 'GPSC.png', 'GULF.png', 'HMPRO.png', 'INTUCH.png', 'IRPC.png', 'IVL.png', 'KBANK.png', 'KCE.png', 'KTB.png', 'MINT.png', 'MTC.png', 'OR.png', 'PLANB.png', 'PTT.png', 'PTTEP.png', 'PTTGC.png', 'RATCH.png', 'SAWAD.png', 'SCB.png', 'SCC.png', 'SCGP.png', 'SIRI.png', 'SPALI.png', 'SPRC.png', 'TISCO.png', 'TLI.png', 'TOA.png', 'TOP.png', 'TRUE.png', 'TTB.png', 'WHA.png'];
  const fetchImages = async () => {
    setLoading(true);
    const blobServiceClient = BlobServiceClient.fromConnectionString(connectionString);
    const containerClient = blobServiceClient.getContainerClient(containerName);
    const path = selectedDate.toISOString().split('T')[0]; // yyyy-mm-dd format
    let blobList = containerClient.listBlobsFlat({ prefix: path });
    let imageUrls = [];
    console.log("-------"+path);
    for await (const blob of blobList) {
      //if (filter === 'Focus' && !focusGroup.includes(blob.name)) continue;
      //if (filter === 'Focus' && !focusGroup.some(group => blob.name.includes(group))) continue;
      if (filter === 'Focus' ){
       if (!focusGroup.some(group => blob.name.includes(group))){
        continue ;
        }
      }
      const blobClient = containerClient.getBlobClient(blob.name);
      const url = blobClient.url;
      console.log(url);
      imageUrls.push(url);
    }
    setImages(imageUrls);
    setLoading(false);
  };
  return (
    <div>
      <div id="SearchDiv" style={{ paddingLeft: '50px', marginBottom: '20px',marginTop: '20px' }}>
        <DatePicker
          selected={selectedDate}
          onChange={(date) => setSelectedDate(date)}
        />
        <select value={filter} onChange={(e) => setFilter(e.target.value)} style={{ marginLeft: '10px', marginRight: '10px' }} > 
          <option value="All">All</option>
          <option value="Focus">Focus</option>
        </select>
        <Button onClick={fetchImages} >Load</Button>
      </div>
      <div id="ImgDiv">
        {loading ? <CircularProgress /> : images.map((image, index) => (
          <img key={index} src={image} alt="blob" style={{ margin: '10px' }} />
        ))}
      </div>
    </div>
  );
}
ReactDOM.render(<ImgRender />, document.getElementById('root'));